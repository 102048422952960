import React from "react";

export const calendar = [
  {
    month: "Janeiro",
    items: [
      {
        data: "20 - Sábado",
        desc: (
          <p>
            Homenagem a Oxossi - Dia de São Sebastião <br /> Direção do Cacique
            Candido - Caboclo Ventania
          </p>
        ),
      },
    ],
  },
  {
    month: "Fevereiro",
    items: [
      {
        data: "01 - Quinta-feira",
        desc: (
          <p>
            XXVI Festa de Yemanjá <br />
            20:00h <br />
            Local: Praia central de Balneário Camboriú, em frente ao Hotel
            Marambaia
          </p>
        ),
      },
    ],
  },
  {
    month: "Março",
    items: [
      {
        data: "09 - Sábado - Nova",
        desc: (
          <p>
            DEMANDA REINO DE OXALÁ E OXUM/CHAPECÓ/SC <br />
            Mãe Ivone da Oxum e Pai Rodrigo de Oxalá
          </p>
        ),
      },
      {
        data: "19 - Terça-feira - Crescente",
        desc: (
          <p>
            20.00hs <br /> INICIO ATIVIDADES REINO JUNA BOMY <br />
            Chamada do BARÁ (ORIXÁ DO ANO)
          </p>
        ),
      },
      {
        data: "22 - Sexta-feira - Crescente",
        desc: (
          <p>ATENDIMENTO PÚBLICO GERAL As 20.00hs CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "25 - Segunda-feira",
        desc: <p>Recesso até o dia 30 em razão da Semana Santa</p>,
      },
    ],
  },
  {
    month: "Abril",
    items: [
      {
        data: "02 - Terça-feira - Minguante",
        desc: (
          <p>DESENVOLVIMENTO MEDIÚNICO As 20.00 hs CHAMADA DE PRETOS VELHOS</p>
        ),
      },
      {
        data: "03 - Quarta-feira - Minguante",
        desc: <p>ATENDIMENTO C/PRETOS VELHOS As 14.30 hs</p>,
      },
      {
        data: "05 - Sexta-feira - Minguante",
        desc: (
          <p>
            ATENDIMENTO PÚBLICO GERAL <br />
            As 20.00 hs CABOCLOS E PRETOS VELHOS
          </p>
        ),
      },
      {
        data: "09 - Terça-feira - Nova",
        desc: <p>DESENVOLVIMENTO MEDIÚNICO As 20.00 hs Chamada de Oxalá</p>,
      },
      {
        data: "10 - Quarta-feira - Nova",
        desc: <p>Atendimento Público com PRETOS VELHOS As 14.30 hs</p>,
      },
      {
        data: "12 - Sexta-feira - Nova",
        desc: (
          <p>ATENDIMENTO PÚBLICO GERAL As 20.00 hs CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "13 - Sábado - Nova",
        desc: (
          <p>
            ENGIRA NO REINO DE OGUM DA LUA (1ª) <br />
            Pai CAIO DE OGUM - SANTA MARIA/RS
          </p>
        ),
      },
      {
        data: "13 - Sábado - NOVA",
        desc: (
          <p>
            DEMANDA NO REINO DE JUNA BOMY (1ª) <br />
            As 20.00 hs Pai Marne e Mãe Marney
          </p>
        ),
      },
      {
        data: "16 - Terça-feira - Crescente",
        desc: <p>- DESENVOLVIMENTO MEDIÚNICO As 20.00 hs Chamada de Yemanjá</p>,
      },
      {
        data: "17 - Quarta-feira - Crescente",
        desc: <p>Atendimento Público c/PRETOS VELHOS As 14.30 hs</p>,
      },
      {
        data: "19 - Sexta-feira - Crescente",
        desc: (
          <p>ATENDIMENTO PÚBLICO GERAL As 20.00 hs CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "20 - Sábado - Nova",
        desc: (
          <p>
            DEMANDA REINO DO PAI BENTO DE ARUANDA <br />
            As 20.00 hs CHAPECÓ/SC - Pai RUI DO OGUM MEGÊ
          </p>
        ),
      },
      {
        data: "23 - Terça-feira - Cheia",
        desc: <p>FESTA PARA OGUM - DIA DE S.JORGE As 20.00 hs</p>,
      },
      {
        data: "27 - Sábado - CHEIA",
        desc: (
          <p>
            ENGIRA NO REINO DE OGUM YTATI -TRÊS PASSOS/RS <br />
            As 20.00 hs - Pai Miguel do Beira Mar e Mãe Rosa da YANSÃ
          </p>
        ),
      },
      {
        data: "30 - Terça-feira - Cheia",
        desc: (
          <p>
            DESENVOLVIMENTO MEDIÚNICO <br />
            As 20.00 hs - Chamada de OXOSSI
          </p>
        ),
      },
    ],
  },
  {
    month: "Maio",
    items: [
      {
        data: "01 - Quarta-Feira",
        desc: <p>NÃO HAVERÁ SESSÃO - MOTIVO: FERIADO NACIONAL</p>,
      },
      {
        data: "03 - Sexta-feira -Minguante",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL -CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "07 - Terça-feira - Minguante",
        desc: <p>NAO HAVERÁ SESSÃO Motivo: concretagem da calçada.</p>,
      },
      {
        data: "08 - Quarta-feira - Nova",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO C/PRETOS VELHOS</p>,
      },
      {
        data: "10 - Sexta-feira - Nova",
        desc: (
          <p>20.00hs - ATENDIMENTO PUBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "13 - Segunda-feira - Nova",
        desc: <p>20.00 hs - FESTA DE PRETOS VELHOS</p>,
      },
      {
        data: "14 - Terça-feira",
        desc: <p>NÃO HAVERÁ SESSÃO - MOTIVO: FESTA DE PRETOS VELHOS</p>,
      },
      {
        data: "15 - Quarta-feira - Crescente",
        desc: <p>14.30 hs - ATENDIMENTO PUBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "17 - Sexta-feira - Crescente",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "21 - Terça-feira - Crescente",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE XANGÔ</p>,
      },
      {
        data: "22 - Quarta-feira - Crescente",
        desc: <p>14.30 hs - ATENDIMENTO COM PRETOS VELHOS</p>,
      },
      {
        data: "24 - Sexta-feira - Cheia",
        desc: <p>20.00 hs - ATENDIMENTO GERAL CABOCLOS E PRETOS VELHOS</p>,
      },
      {
        data: "28 - Terça-feira - Cheia",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXUM</p>,
      },
      {
        data: "29 - Quarta-feira - Cheia",
        desc: <p>14.30hs - ATENDIMENTO PUBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "31 - Sexta-feira - Cheia",
        desc: <p>20.00 hs - ATENDIMENTO GERAL CABOCLOS E PRETOS VELHOS</p>,
      },
    ],
  },
  {
    month: "Junho",
    items: [
      {
        data: "04 - Terça-feira - Minguante",
        desc: (
          <p>20.00HS - Desenvolvimento Mediúnico - Chamada de PRETOS VELHOS</p>
        ),
      },
      {
        data: "05 - Quarta-feira - Minguante",
        desc: <p>14.30 HS - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "06 - Quinta-feira - Nova",
        desc: (
          <p>20.00 hs - ATENDIMENTO PUBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "08 - Sábado - NOVA",
        desc: (
          <p>
            20.00 HS - DEMANDA NO REINO DE OGUM DA LUA - STA MARIA/RS <br />
            Pai Caio do Ogum da Lua
          </p>
        ),
      },
      {
        data: "11 - Terça-feira - Nova",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YANSÃ</p>,
      },
      {
        data: "12 - Quarta-feira - Nova",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "13 - Quinta-feira - Crescente",
        desc: <p>ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>,
      },
      {
        data: "15 - Sábado - CRESCENTE",
        desc: (
          <p>
            - 20.00HS - ENGIRA NO REINO JUNA BOMY (1ª) <br />
            Pai Marne e Mãe Marney da Oyá Anayá
          </p>
        ),
      },
      {
        data: "18 - Terça-feira - Crescente",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIUNICO - CHAMADA DE OXALÁ</p>,
      },
      {
        data: "19 - Quarta-feira - Crescente",
        desc: <p>14.30HS - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "20 - Quinta-feira - Cheia",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "24 - Segunda-feira - Cheia",
        desc: (
          <p>
            20.00 hs - DIA DE SÃO JOÃO BATISTA - BATIZADOS ADULTOS <br />
            Chamada do POVO DO ORIENTE
          </p>
        ),
      },
      {
        data: "25 - Terça-feira",
        desc: <p>NÃO HAVERÁ SESSÃO - MOTIVO: DIA DE SÃO JOAO BATISTA</p>,
      },
      {
        data: "26 - Quarta-feira - Cheia",
        desc: <p>14.30 HS - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "27 - Quinta-feira - Minguante",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL-CABOCLOS E PRETOS VELHOS</p>
        ),
      },
    ],
  },
  {
    month: "Julho",
    items: [
      {
        data: "02 - Terça-feira - Minguante",
        desc: <p>20.00hs - DESENV. MEDIÚNICO - CHAMADA DE PRETOS VELHOS</p>,
      },
      {
        data: "03 - Quarta-feira - Minguante",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "04 - Quinta-feira - Nova",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "06 - Sábado - Nova",
        desc: (
          <p>
            20.00 hs - Engira no centro espiritualista PAI BENTO DE ARUANDA em
            CHAPECÓ, do pai Rui de Ogum.
          </p>
        ),
      },
      {
        data: "09 - Terça-feira - Nova",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO CHAMA DA DE YEMANJÁ</p>,
      },
      {
        data: "10 - Quarta-feira - Nova",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "11 - Quinta-feira - Nova",
        desc: (
          <p>20:00 hs - ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "13 - Sábado - NOVA",
        desc: (
          <p>
            20.30 - ENGIRA NO REINO DE OXALÁ E OXUM - CHAPECÓ/SC <br />
            Mãe Ivone da Oxum e Pai Rodrigo de Oxalá
          </p>
        ),
      },
      {
        data: "16 - Terça-feira - Crescente",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OGUM</p>,
      },
      {
        data: "17 - Quarta-feira - Crescente",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "18 - Quinta-feira - Crescente",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "23 - Terça-feira - Cheia",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXOSSI</p>,
      },
      {
        data: "24 - Quarta-feira - Cheia",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "25 - Quinta-feira - Cheia",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "31 - Quarta-feira - Minguante",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
    ],
  },
  {
    month: "Agosto",
    items: [
      {
        data: "01 - Quinta-feira - Minguante",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "06 - Terça-feira - Nova",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE XANGÔ</p>,
      },
      {
        data: "07 - Quarta-feira - Nova",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "08 - Quinta-feira - Nova",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "10 - Sábado - NOVA",
        desc: (
          <p>
            20.00 HS - DEMANDA NO REINO DE OGUM YTATI - TRÊS PASSOS/RS <br />
            Pai Miguel do Beira Mar e Mãe Rosa da Yansã
          </p>
        ),
      },
      {
        data: "10 - Sábado - NOVA",
        desc: (
          <p>
            20.00 HS - DEMANDA NO REINO DE OGUM DA LUA - SANTA MARIA/RS <br />
            Pai CAIO DO OGUM
          </p>
        ),
      },
      {
        data: "13 - Terça-feira - Crescente",
        desc: <p>20.00 hs -DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXUM</p>,
      },
      {
        data: "14 - Quarta-feira - Crescente",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "15 - Quinta-feira - Crescente",
        desc: <p>14.30 hs - sessão de atendimento Geral, 20h</p>,
      },
      {
        data: "17 - Sábado - CRESCENTE",
        desc: (
          <p>
            20.00 HS-TRABALHO DE DEMANDA NO REINO DE JUNA BOMY(2ª) <br />
            Mestre Marne e Mãe Marney da Oyá Anayá
          </p>
        ),
      },
      {
        data: "21 - Quarta-feira - CHEIA",
        desc: <p>CHAMADA DE CATIMBÓ - FECHADA AO PÚBLICO</p>,
      },
      {
        data: "22 - Quinta-feira - Cheia",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL - CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "27 - Terça-feira - Minguante",
        desc: (
          <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO-CHAMADA DE PRETOS VELHOS</p>
        ),
      },
      {
        data: "28 - Quarta-feira - Minguante",
        desc: <p>14.30 HS - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "29 - Quinta-feira - Minguante",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
    ],
  },
  {
    month: "Setembro",
    items: [
      {
        data: "03 - Terça-feira - Nova",
        desc: <p>20.00hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXALÁ</p>,
      },
      {
        data: "04 - Quarta-feira - Nova",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "05 - Quinta-feira - Nova",
        desc: (
          <p> 20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "10 - Terça-feira - Nova",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO CHAMADA DE YEMANJÁ</p>,
      },
      {
        data: "11 - Quarta-feira - Nova",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "12 - Quinta-feira - Crescente",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "14 - Sábado-CRESCENTE-",
        desc: <p>20h ENGIRA (Segunda engira)</p>,
      },
      {
        data: "17 - Terça-feira - Cheia",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO CHAMADA DE OGUM</p>,
      },
      {
        data: "18 - Quarta-feira - Cheia",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "19 - Quinta-feira - Cheia",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "21 - Sábado",
        desc: <p>AS 20.00 HS - AMACI DE CABEÇA</p>,
      },
      {
        data: "21 - Sábado-CRESCENTE-",
        desc: (
          <p>
            20.00 hs DEMANDA EM FRED. WESTPHALEN/RS -REINO OGUM MAREMAR 2ª MÃE
            DULCE DE YANSÃ
          </p>
        ),
      },
      {
        data: "24 - Terça-feira - Minguante",
        desc: (
          <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO CHAMADA DE PRETOS VELHOS</p>
        ),
      },
      {
        data: "25 - Quarta-feira - Minguante",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "26 - Quinta-feira - MINGUANTE",
        desc: <p>não haverá sessão, motivo: Festa de Cosme</p>,
      },
      {
        data: "27 - Sexta-feira - MINGUANTE",
        desc: <p>20h, Festa de Cosme e Damião, Batizado infantil.</p>,
      },
      {
        data: "30 - Segunda-feira",
        desc: <p>20.00 hs - HOMENAGEM A XANGÔ E ANIVERSARIO DO REINO</p>,
      },
    ],
  },
  {
    month: "Outubro",
    items: [
      {
        data: "01 - Terça-feira",
        desc: <p>NÃO HAVERÁ SESSÃO. MOTIVO FESTA DE XANGÔ</p>,
      },
      {
        data: "02 - Quarta-feira - Nova",
        desc: <p>14.30 hs - ATENDIMENTO COM PRETOS VELHOS</p>,
      },
      {
        data: "03 - Quinta-feira - Nova",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "08 - Terça-feira - Nova",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO CHAMADA DE OXOSSI</p>,
      },
      {
        data: "09 - Quarta-feira - Nova - CANCELADA",
        desc: (
          <p>
            Devido aos alertas de temporal de hoje, decidimos cancelar a sessão
            desta quarta-feira (9/10)
          </p>
        ),
      },
      {
        data: "10 - Quinta-feira - Crescente",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERALCABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "12 - Sábado - CRESCENTE",
        desc: (
          <p>
            20.00 HS- DEMANDA EM CHAPECÓ/SC - Reino de Oxalá e Oxum/ Chapecó
            (SC) <br />
            Mãe Ivone de Oxum e Pai Rodrigo de Oxalá.
          </p>
        ),
      },
      {
        data: "15 - Terça-feira - Crescente",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE XANGÔ</p>,
      },
      {
        data: "16 - Quarta-feira - Crescente",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "17 - Quinta-feira - Cheia",
        desc: (
          <p>
            20.00hs - ATENDIMENTO PÚBLICO GERAL COM CABOCLOS E PRETOS VELHOS
          </p>
        ),
      },
      {
        data: "19 - Sábado - CHEIA",
        desc: (
          <p>
            20.00 HS-TRAB. DEMANDA NO REINO JUNA BOMY(3ª) <br />
            Mestre Marne e Mãe Marney da Oyá Anayá
          </p>
        ),
      },
      {
        data: "19 - Sábado - CHEIA",
        desc: (
          <p>
            20:00hs TRABALHO DE DEMANDA NO REINO DO PAI BENTO DE ARUANDA
            <br />
            CHAPECÓ/SC - Pai RUI DO OGUM MEGÊ
          </p>
        ),
      },
      {
        data: "22 - Terça-feira - Cheia",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO CHAMADA DE OXUM</p>,
      },
      {
        data: "23 - Quarta-feira - Cheia",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "24 - Quinta-feira - Minguante",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "29 - Terça-feira - Minguante",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO CHAMADA PRETOS VELHOS</p>,
      },
      {
        data: "30 - Quarta-feira - Minguante",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "31 - Quinta-feira - Nova",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
    ],
  },
  {
    month: "Novembro",
    items: [
      {
        data: "05 - Terça-feira - Nova",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YANSÃ</p>,
      },
      {
        data: "06 - Quarta-feira - Nova",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "07 - Quinta-feira - Nova",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "09 - Sábado - CRESCENTE",
        desc: (
          <p>
            20.00 HS-ENGIRA NO REINO DE OGUM DA LUA - STA. MARIA/RS <br />
            Pai CAIO DE OGUM
          </p>
        ),
      },
      {
        data: "12 - Terça-feira - crescente",
        desc: <p>20.00 hs - ATENDIMENTO PÚBLICO CHAMADA DE OXALÁ</p>,
      },
      {
        data: "13 - Quarta-feira - Crescente",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "14 - Quinta-feira",
        desc: <p>FERIADO NACIONAL - NÃO HAVERÁ SESSÃO</p>,
      },
      {
        data: "19 - Terça-feira - Cheia",
        desc: <p>20.00 hs -DESENVOLVIMENTO MEDIUNICO CHAMADA DE YEMANJÁ</p>,
      },
      {
        data: "20 - Quarta-feira - Cheia",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "21 - Quinta-feira - Minguante",
        desc: (
          <p>20.00 hs - ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "23 - Sábado",
        desc: (
          <p>
            20.00 HS - ENGIRA NO REINO JUNA BOMY (2ª) BALN. CAMBORÚ/RS ÚLTIMA do
            ANO <br />
            Mestre Marne e Mãe Marney da Oyá Anayá
          </p>
        ),
      },
      {
        data: "26 - Terça-feira - Minguante",
        desc: <p>20.00 hs - DESENVOLVIMENTO MEDIÚNICO CHAMADA PRETOS VELHOS</p>,
      },
      {
        data: "27 - Quarta-feira - Minguante",
        desc: <p>14.30 hs - ATENDIMENTO PÚBLICO COM PRETOS VELHOS</p>,
      },
      {
        data: "28 - Quinta-feira - Minguante",
        desc: (
          <p>20.00 hs -ATENDIMENTO PÚBLICO GERAL CABOCLOS E PRETOS VELHOS</p>
        ),
      },
    ],
  },
  {
    month: "Dezembro",
    items: [
      {
        data: "03 - Terça-feira",
        desc: <p>NÃO HAVERÁ SESSÃO - MOTIVO: FESTA DA YANSÃ</p>,
      },
      {
        data: "04 - Quarta-feira - NOVA",
        desc: <p>20.00 HS - FESTA DA YANSÃ (DIA DE STA. BARBARA)</p>,
      },
      {
        data: "05 - Quinta-feira",
        desc: (
          <p>20.00 HS - Atendimento Público com CABOCLOS E PRETOS VELHOS</p>
        ),
      },
      {
        data: "05 - Quinta-feira - Nova",
        desc: (
          <p>
            20.00 hs - ENTREGA DO ANO NO REINO DO PAI BENTO DE ARUANDA <br />
            CHAPECÓ/SC - Pai RUI DO OGUM MEGÊ
          </p>
        ),
      },
      {
        data: "07 - Sábado - Nova",
        desc: (
          <p>
            20.00 hs - ENTREGA DO ANO NO REINO DE OXALÁ E OXUM - CHAPECÓ/SC{" "}
            <br />
            Mãe IVONE DA OXUM E Pai RODRIGO DE OXALÁ
          </p>
        ),
      },
      {
        data: "07 - Sábado - NOVA",
        desc: (
          <p>
            20.00 HS - HOMENAGEM A OXUM REINO DE JUNA BOMY <br />
            Direção de PAULINHO DE OGUM DA LUA ou JAIR DO CABOCLO ZANDO
          </p>
        ),
      },
      {
        data: "09 - Segunda-feira - Crescente",
        desc: (
          <p>
            20.00 hs - ENTREGA DO ANO NO REINO DE OGUM MAREMAR <br />
            FRED. WESTPHALEN/RS Mãe DULCE DE YANSÃ
          </p>
        ),
      },
      {
        data: "10 - Terça-feira - Crescente",
        desc: (
          <p>
            20.00 hs - ENTREGA DO ANO NO REINO DE OGUM YTATI NA CIDADE DE TRES
            PASSOS/RS <br /> PAI MIGUEL DO OGUM BEIRA E MÃE ROSA DA YANSÃ
          </p>
        ),
      },
      {
        data: "12 - Quinta-feira - Crescente",
        desc: (
          <p>
            - 20.00 hs - ENTREGA DO ANO NO REINO SANTO ANTÔNIO <br />
            SANTA MARIA/RS Pai FERNANDO do XANGÔ D’JAKUTÁ
          </p>
        ),
      },
      {
        data: "17 - Terça-feira - CHEIA",
        desc: (
          <p>
            20.00HS - ENTREGA DO ANO NO REINO DE JUNA BOMY <br />
            BALNEÁRIO CAMBORIÚ/SC - Mestre Mane e Mãe Marney da OYÁ ANAYÁ <br />
            ENCERRAMENTO DAS ATIVIDADES DO REINO JUNA BOMY DO ANO 2024
          </p>
        ),
      },
    ],
  },
];
